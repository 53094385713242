import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let imageUrl;

  if (windowWidth <= 360) {
    imageUrl = '/coming_soon_small_2024-03-01_09-25-06.jpg'; // Small image for screens <= 360px
  } else if (windowWidth <= 1022) {
    imageUrl = '/coming_soon_medium_2024-03-01_09-25-06.jpg'; // Medium image for screens <= 921px
  } else {
    imageUrl = '/coming_soon_large_2024-03-01_09-25-06.jpg'; // Large image for screens >= 922px
  }
  return (
    <div className="App">
      <img src={imageUrl} alt="coming-soon" />
    </div>
  );
}

export default App;
